import { translate } from '@/domains/core/localization/translate';
export const {
  title,
  subTitle,
  text,
  button
} = translate({
  "title": "Fehler 404",
  "subTitle": "Ups, das wollten wir nicht. Sorry!",
  "text": "Folgen Sie den Anweisungen, wir leiten Sie um.",
  "button": "Zur\xFCck zur Startseite"
});